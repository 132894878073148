// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-gracias-por-contactarnos-tsx": () => import("./../../../src/pages/gracias-por-contactarnos.tsx" /* webpackChunkName: "component---src-pages-gracias-por-contactarnos-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-templates-contactanos-page-tsx": () => import("./../../../src/templates/contactanos-page.tsx" /* webpackChunkName: "component---src-templates-contactanos-page-tsx" */),
  "component---src-templates-procesos-de-calidad-page-tsx": () => import("./../../../src/templates/procesos-de-calidad-page.tsx" /* webpackChunkName: "component---src-templates-procesos-de-calidad-page-tsx" */),
  "component---src-templates-productos-page-tsx": () => import("./../../../src/templates/productos-page.tsx" /* webpackChunkName: "component---src-templates-productos-page-tsx" */),
  "component---src-templates-quienes-somos-page-tsx": () => import("./../../../src/templates/quienes-somos-page.tsx" /* webpackChunkName: "component---src-templates-quienes-somos-page-tsx" */)
}

